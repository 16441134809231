// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  jobsApi: 'https://unigql.quodd.dev/jobs',
  templateNamesApi: 'https://qdod-api.quodd.dev/getList',
  downloadApi: 'https://unigql.quodd.dev/download',
  exceptionsApi: 'https://unigql.quodd.dev/download',
  gqlUrl: 'https://unigql.quodd.dev/message',
  submitQueryJob: 'https://qdod-api.quodd.dev/submitQueryJob',
  authRedirectUrl: 'https://auth.quodd.dev',
  saveCustomTemplate: `https://qdod-api.quodd.dev/postObject`,
  loadCustomTemplate: `https://qdod-api.quodd.dev/getDetail?template=`,
  mixpanelToken: 'b5413bb4672253f3d6439a371ff7dedb',
  fieldsDescApi: 'https://app.quodd.dev/qx-query-graphql/',
  instrumentListsUrl: 'https://48gyizevxg.execute-api.us-east-2.amazonaws.com',
  deleteReportApi: 'https://unigql.quodd.dev/delete',
  enableInstrumentsFlow: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
