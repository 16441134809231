<div>
  <mat-card appearance="outlined" class="!p-8 !rounded-md !border-none">
    <div class="header-one text-blue-active">Select Data Fields</div>

    <div class="mb-4">
      <div class="grid grid-cols-3 gap-4">
        <div>
          <mat-form-field class="mt-0 w-full" appearance="fill">
            <mat-label>Load Query</mat-label>
            <mat-select [disabled]="templateListDisabled" [(value)]="selected">
              <mat-optgroup label="Custom">
                <!-- check if templateNames length is 0 or not -->
                <mat-option *ngIf="templateNames.length === 0" [value]="null">No Custom Templates</mat-option>

                <mat-option *ngFor="let template of templateNames" [value]="template.Name"
                  (click)="setTemplateFields(template.Name); selectionEvent(template.Name)">{{ template.Name }}</mat-option>
            
                </mat-optgroup>

              <mat-optgroup label="Predefined">
                <mat-option *ngFor="let pre_item of predefinedTemplates" (click)="
                    setTemplateFields(pre_item); selectionEvent(pre_item)
                  " [value]="pre_item.value">{{ pre_item.name }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <div>
            <mat-form-field appearance="outline" class="w-full">
              <input matInput #input [(ngModel)]="searchString" autocomplete="off" placeholder="Search " type="search"
                aria-label="Search " (keyup.enter)="fieldFilter($event)" (focus)="onFocusInChangeImg()"
                (focusout)="onFocusOutChangeImg()" />
              <span matSuffix class="flex"><img [src]="imgSrc" alt="search" /></span>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 mb-2">
        <div class="border overflow-y-auto h-45 mt-2">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
              [style.display]="filterLeafNode(node) ? 'none' : 'flex'">
              <button mat-icon-button disabled></button>
              <div>
                <div class="float-left">
                  <mat-checkbox color="primary" [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{ node.viewValue }}</mat-checkbox>
                </div>
                <div class="float-left clear-left ml-1.87 mt-0.31 mb-0.93">
                  <label class="text-[12.8px]">{{ node.desc }}</label>
                </div>
              </div>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
              [style.display]="filterParentNode(node) ? 'none' : 'flex'">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                  treeControl.isExpanded(node)
                  ? 'expand_more'
                  : 'chevron_right'
                  }}
                </mat-icon>
              </button>
              <mat-checkbox color="primary" [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node, $event.checked)">{{ node.viewValue }}</mat-checkbox>
            </mat-tree-node>
          </mat-tree>
        </div>

        <div cdkDropListGroup class="overflow-y-auto h-45 border ml-2 pt-0 p-4 mt-2">
          <div class="grid grid-cols-3 gap-7 p-2 border-bottom mb-2">
            <div class="col-span-2 text-muted font-gray fs-5">
              SELECTED FIELDS
            </div>
            <div class="text-blue-custom">{{ filteredSelectedData.length }} Item/s</div>
          </div>
          <div class="example-container">
            <div cdkDropList [cdkDropListData]="filteredSelectedData" class="mb-3 example-list"
              (cdkDropListDropped)="drop($event)">
              <div cdkDrag *ngFor="let field of filteredSelectedData">
                <div class="grid grid-cols-2 cursor-move text-sm break-words">
                  <div>{{ parentCodeViewValues[field.parent] }}</div>
                  <div class="text-right">{{ field.viewValue }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-4 text-right">
      <button type="button " class="mr-4 btn-theme-secondary" (click)="toggleReportpage()">
        Cancel
      </button>
      <button type="button" matStepperNext class="btn-theme-primary">
        Next
      </button>
    </div>
  </mat-card>
</div>