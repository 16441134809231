<div class="px-8">
  <div class="h-[80vh] relative overflow-y-auto">
    <app-spinner [state]="spinnerStatus"></app-spinner>

    <table
      mat-table
      [dataSource]="dataSource"
      class="!p-3 rounded-5 w-full bg-white"
      aria-describedby="ReportsTable"
    >
      <tr class="mat-row" *matNoDataRow class="text-center">
        <td class="mat-cell !p-3 break-all" colspan="9999">
          <p class="text-lg font-semibold">No Jobs Available</p>
          <p class="lead text-base mt-1">Please submit your first job.</p>
        </td>
      </tr>

      <!-- Generated Report Column -->
      <ng-container matColumnDef="generated_report" class="mr-2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="header-three !p-3 !text-blue-active !bg-light-gray sticky top-0 z-50"
        >
          View your reports
        </th>
        <td
          mat-cell
          mat-cell
          class="!p-3 break-all w-[40%]"
          *matCellDef="let element"
        >
          <div>
            <ul class="!flex">
              <div class="icon-container">
                <li class="bg-white-smoke rounded-circle-radius">
                  <div class="icon">
                    <img
                      class="p-3 h-10"
                      src="../../../assets/select-file.svg"
                      alt="file_logo"
                    />
                  </div>
                </li>
              </div>
              <div class="ml-4 cursor-default">
                <span>
                  <div class="header-two text-blue-active">
                    {{ element.template_name }}
                  </div>
                </span>
                <div>
                  <span class="mt-2 body-text-two font-gray">{{
                    getTime(element.request_time)
                  }}</span>
                  <span
                    class="border-radius customBadge"
                    [ngStyle]="styleObject(element.job_status)"
                    >{{ element.job_status }}</span
                  >
                </div>
              </div>
            </ul>
            <div class="text-left !float-left"></div>
          </div>
        </td>
      </ng-container>

      <!-- Report Name Column -->
      <ng-container matColumnDef="report_name" class="mr-2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="body-text-one !text-center !p-3 !text-blue-active !bg-light-gray sticky top-0 z-50"
        >
          Report
        </th>
        <td
          mat-cell
          class="!p-3 break-all !text-center w-[5%]"
          *matCellDef="let element"
        >
          <div
            *ngIf="element.job_status != 'In Progress'; else elseBlocks"
            class="text-center"
          >
            <a
              *ngIf="element.report_template_name != 'N/A'; else elseBlocks"
              class="cursor-pointer table-fields"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{
                element.job_status === 'In Progress'
                  ? 'N/A'
                  : element.report_template_name
              }}"
            >
              <span>
                <mat-icon
                  class="align-middle cursor-pointer text-center"
                  (click)="downloadResponseFile(element)"
                  >downloading</mat-icon
                ></span
              >
            </a>
          </div>
          <ng-template #elseBlocks
            ><u
              (click)="downloadResponseFile(element)"
              class="cursor-pointer table-fields"
              >N/A</u
            >
          </ng-template>
        </td>
      </ng-container>

      <!-- Exceptions Column -->
      <ng-container matColumnDef="exceptions" class="mr-2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="body-text-one !text-center !p-3 !text-blue-active !bg-light-gray sticky top-0 z-50"
        >
          Exceptions
        </th>
        <td
          mat-cell
          *matCellDef="let element; &quot;exceptions-style&quot;"
          class="!p-3 break-all !text-center w-[5%]"
        >
          <div
            *ngIf="
              element.exception_report_key;
              else elseBlock;
              &quot;text-center&quot;
            "
            class="text-center"
          >
            <a
              class="cursor-pointer table-fields"
              data-toggle="tooltip"
              data-placement="bottom"
              title="{{
                !element.exception_report_key
                  ? 'N/A'
                  : element.template_name + '.csv'
              }}"
            >
              <span
                ><mat-icon
                  class="align-middle cursor-pointer"
                  (click)="downloadExceptionsFile(element)"
                  >downloading</mat-icon
                ></span
              >
            </a>
          </div>
          <ng-template #elseBlock
            ><u
              (click)="downloadExceptionsFile(null)"
              class="cursor-pointer table-fields"
              >N/A</u
            >
          </ng-template>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" class="mr-2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="body-text-one !text-center !p-3 !text-blue-active !bg-light-gray sticky top-0 z-50"
        >
          Actions
        </th>
        <td
          class="text-center !p-3 break-all w-[20%]"
          mat-cell
          *matCellDef="let element"
        >
          <mat-form-field appearance="fill" class="field-background">
            <mat-label>File Format</mat-label>
            <mat-select
              value="csv"
              (selectionChange)="fileFormatSelection($event, element)"
              class="text-[14px]"
            >
              <mat-option value="csv">csv</mat-option>
              <mat-option value="csv_piped">pipe</mat-option>
              <mat-option value="json">json</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <!-- Delete Report -->
      <ng-container matColumnDef="deleteJob" class="mr-2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="!bg-light-gray sticky top-0 z-50 w-[10%] !p-3"
        ></th>
        <td
          class="text-center !pl-5 break-all"
          mat-cell
          *matCellDef="let element"
        >
          <div
            *ngIf="
              element.job_status !== 'In Progress';
              else elseBlock;
              &quot;text-center&quot;
            "
            class="text-center"
          >
            <a
              class="cursor-pointer"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete Report"
            >
              <span
                class="material-symbols-rounded text-red-500"
                (click)="openDeleteDialog(element.job_id)"
                >delete</span
              >
            </a>
          </div>
          <ng-template #elseBlock
            ><span
              class="material-symbols-rounded text-gray-400 pointer-events-none"
              >delete</span
            >
          </ng-template>
        </td>
      </ng-container>

      <!-- Exceptions Column -->
      <ng-container matColumnDef="saveTemplate">
        <th
          class="!p-3 !text-blue-active !bg-light-gray sticky top-0 z-50"
          mat-header-cell
          *matHeaderCellDef
        ></th>
        <td
          mat-cell
          *matCellDef="let element"
          class="!text-center !p-3 break-all w-[20%]"
        >
          <button
            type="button"
            class="btn-theme-primary text-wrap text-xs break-all font-display"
            (click)="openDialog(element)"
          >
            Save Query
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="loadMore">
        <td
          class="!text-center !p-3"
          mat-footer-cell
          *matFooterCellDef
          colspan="9999"
          (click)="loadNextJobs()"
        >
          <p class="text-white break-all">Load more</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <ng-container>
        <tr
          mat-footer-row
          *matFooterRowDef="['loadMore']"
          class="!bg-blue-custom !text-white cursor-pointer hover:!bg-gray-custom"
          [hidden]="dataSource.length >= 20 ? false : true"
        ></tr>
      </ng-container>
    </table>
  </div>
</div>
