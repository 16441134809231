import { Component, OnInit } from '@angular/core';
import { QdodService } from 'src/app/common/services/qdod.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { SaveTemplateComponent } from '../save-template/save-template.component';
import { MixpanelService } from 'src/app/common/services/mixpanel.service';
import { DeleteReportDialogComponent } from '../delete-report-dialog/delete-report-dialog.component';
import { DateTime } from 'luxon';

export interface ReportElement {
  JobId: string;
  LastModified: string;
  generated_report: string;
  JobStatus: string;
  report_name: string;
  exceptions: string;
  actions: string;
}
interface Extensions {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.css'],
})
export class ReportsTableComponent implements OnInit {
  displayedColumns: string[] = [
    'generated_report',
    'report_name',
    'exceptions',
    'actions',
    'deleteJob',
    'saveTemplate',
  ];
  dataSource: any = [];
  spinnerStatus = false;
  loadMoreFlag: boolean = this.dataSource.length >= 20 ? true : false;
  extensions: Extensions[] = [
    { value: 'csv', viewValue: 'csv' },
    { value: 'json', viewValue: 'json' },
    { value: 'xls', viewValue: 'xls' },
    { value: 'xml', viewValue: 'xml' },
  ];
  selectedFormat: any = 'csv';
  interval: any;
  intervalId: any;
  job_id: string;
  flag = false;
  lastJobId: any;
  count = 0;

  styleObject(status: string): object {
    if (status == 'Completed') {
      return { color: '#42ab7b', backgroundColor: '#e6f5ef' };
    } else if (status == 'Failed') {
      return { color: '#d64c29', backgroundColor: '#fbebe7' };
    } else if (status == 'In Progress') {
      return { color: '#2385c1', backgroundColor: '#e7f2f8' };
    }
    return {};
  }

  openDeleteDialog(job_id: string): void {
    const dialogRef = this.dialog.open(DeleteReportDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      result && this.deleteReport(job_id);
    });
  }

  downloadResponseFile(element: any) {
    this.mixpanelService.onDownloadEvent('response file');
    let format: any = null;
    if (element.report_template_name === 'N/A') {
      this.toastr.error(`The file is Not Available`);
    } else if (element.report_template_name.includes('_piped')) {
      format = 'csv_piped';
    } else if (element.report_template_name.includes('.csv')) {
      format = 'csv';
    } else {
      format = 'json';
    }

    if (element[`${format}_report_key`]) {
      this.reportService
        .downloadFileApi(element.job_id, format)
        .subscribe((download_obj: any) => {
          if (download_obj.url) {
            window.open(download_obj.url);
          } else {
            this.toastr.error(`The ${format} file cannot be downloaded`);
          }
        });
    }
  }

  downloadExceptionsFile(element: any) {
    this.mixpanelService.onDownloadEvent('exceptions file');
    if (!element) {
      this.toastr.error('The file is Not Available');
    } else {
      this.reportService
        .exceptionsFileApi(element.job_id, element.exception_report_key)
        .subscribe((download_obj: any) => {
          if (download_obj.url) {
            window.open(download_obj.url);
          } else {
            this.toastr.error('The file cannot be downloaded');
          }
        });
    }
  }
  fileFormatSelection(event: any, element: any) {
    const format = event.value;
    this.mixpanelService.onListSelection('file-format-change', format);
    this.job_id = element.job_id;
    if (event.value === 'csv') {
      element.report_template_name = element.csv_report_key
        ? `${element.template_name}.${event.value}`
        : 'N/A';
    } else if (event.value == 'csv_piped') {
      element.report_template_name = element.csv_piped_report_key
        ? `${element.template_name}_piped.csv`
        : 'N/A';
    } else {
      element.report_template_name = element.json_report_key
        ? `${element.template_name}.${event.value}`
        : 'N/A';
    }
    this.selectedFormat = event.value;
  }

  deleteReport(job_id: string) {
    this.reportService.deleteReportApi(job_id).subscribe((res) => {
      if (res.data.status == 'Success') {
        this.toastr.success(res.data.message);
        this.getUpdatedJobsList();
      } else if (res.data.status === 'Failed') {
        this.toastr.error(res.data.message);
      } else {
        this.toastr.error(res);
      }
    });
  }

  constructor(
    private reportService: QdodService,
    private mixpanelService: MixpanelService,
    public toastr: ToastrService,
    public dialog: MatDialog,
  ) {}

  getTime(timestamp: string) {
    return DateTime.fromISO(timestamp).toFormat('MM-dd-yyyy h:mm a');
  }

  getLastJobIndex() {
    const length = this.dataSource.length;
    this.lastJobId = this.dataSource[length - 1].job_id;
    return this.lastJobId;
  }
  openDialog(element: any) {
    this.mixpanelService.onButtonClick('save as template');
    this.dialog.open(SaveTemplateComponent, {
      panelClass: 'makeItMiddle',
      autoFocus: false,
      data: {
        dataElement: element,
      },
    });
  }

  loadNextJobs() {
    this.mixpanelService.onButtonClick('load-more-reports');
    this.reportService
      .NextJobsList(this.getLastJobIndex())
      .subscribe((res: any) => {
        if (res !== undefined) {
          res.data.map((obj: any) => {
            if (obj.csv_report_key) {
              obj.report_template_name = `${obj.template_name}.csv`;
            } else {
              obj.report_template_name = 'N/A';
            }
          });
          this.dataSource = [...this.dataSource, ...res.data];
        }
      });
  }

  JobsPollingApi() {
    let newObjArr: any = [];
    let flag = false;
    this.reportService.ResultListApi().subscribe((res: any) => {
      if (res !== undefined) {
        res.data.map((obj: any) => {
          flag = false;
          if (obj.csv_report_key) {
            obj.report_template_name = `${obj.template_name}.csv`;
          } else {
            obj.report_template_name = 'N/A';
          }
          this.dataSource.map((resObj: any, index: any) => {
            if (resObj.job_id === obj.job_id) {
              if (
                resObj.job_status === 'In Progress' &&
                obj.job_status === 'Completed'
              ) {
                this.dataSource[index] = obj;
              } else if (
                resObj.job_status === 'In Progress' &&
                obj.job_status === 'Failed'
              ) {
                this.dataSource[index] = obj;
              }
              flag = true;
            }
          });
          if (!flag) {
            newObjArr.push(obj);
          }
        });
        this.dataSource = [...this.dataSource];
      }
      if (newObjArr.length > 0) {
        this.dataSource = [...newObjArr, ...this.dataSource];
        if (this.dataSource.length > 20) {
          this.dataSource = this.dataSource.slice(0, 20);
        }
        newObjArr = [];
      }
    });
  }

  updateCount() {
    this.count += 100;
    if (this.count === 500 && this.dataSource?.data?.length === 0) {
      this.spinnerStatus = true;
    }
  }

  checkApiTime() {
    this.intervalId = setInterval(() => this.updateCount(), 100);
    setTimeout(() => {
      clearInterval(this.intervalId);
    }, 500);
  }

  getUpdatedJobsList() {
    this.checkApiTime();

    this.reportService.ResultListApi().subscribe(
      (res: any) => {
        if (res) {
          this.dataSource = res.data;
          this.loadMoreFlag = this.dataSource.length >= 20;
          this.dataSource.forEach((obj: any) => {
            obj.report_template_name = obj.csv_report_key
              ? `${obj.template_name}.csv`
              : 'N/A';
          });
          this.spinnerStatus = false;
        }
      },
      (error: any) => {
        this.spinnerStatus = false;
      },
    );
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();

    this.getUpdatedJobsList();

    this.interval = setInterval(() => {
      this.JobsPollingApi();
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
